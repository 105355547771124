import Router from 'next/router';
import getConfig from 'next/config';
import { TokenPurpose } from '../cookies.shared';
import {
    setTokenCookie,
    removeRedirectCookie,
    removeTokenCookies,
} from './cookies';

export const login = (accessToken: string, refreshToken: string): void => {
    setTokenCookie(accessToken, TokenPurpose.Access);
    setTokenCookie(refreshToken, TokenPurpose.Refresh);
    removeRedirectCookie();
};

export const logout = async (): Promise<void> => {
    removeTokenCookies();

    const params = new URLSearchParams(window.location.search);
    if (params.get('return') === '0') {
        await Router.push('/' + window.location.search);
    } else {
        const { publicRuntimeConfig } = getConfig();
        const is1Url = publicRuntimeConfig.NEXT_PUBLIC_IS1_URL;
        if (is1Url) {
            const logoutUrl = new URL(is1Url);
            logoutUrl.pathname = '/public/logout';
            logoutUrl.search = '?return=homepage';
            window.location.href = logoutUrl.toString();
        } else {
            await Router.push('/' + window.location.search);
        }
    }
};
